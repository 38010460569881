import { render, staticRenderFns } from "./position_level_dialog.vue?vue&type=template&id=36581b8e&scoped=true&"
import script from "./position_level_dialog.vue?vue&type=script&lang=js&"
export * from "./position_level_dialog.vue?vue&type=script&lang=js&"
import style0 from "./position_level_dialog.vue?vue&type=style&index=0&id=36581b8e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36581b8e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36581b8e')) {
      api.createRecord('36581b8e', component.options)
    } else {
      api.reload('36581b8e', component.options)
    }
    module.hot.accept("./position_level_dialog.vue?vue&type=template&id=36581b8e&scoped=true&", function () {
      api.rerender('36581b8e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "found/views/sfa/views/activity/program_manage/form/components/position_level_dialog.vue"
export default component.exports